/* application.scss */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "car_swiper";

.flash-message {
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  padding: 1rem;
}

.rotate-minus-90 {
  transform: rotate(-90deg);
}
