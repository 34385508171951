.swiper {
  --swiper-theme-color: #A0A0A0;
}

.swiper-button-next, .swiper-button-prev {
  background-color: #1f2937;
  border-radius: 50%;
  //--swiper-navigation-size: 40px;
  width: 30px !important;
  height: 30px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.swiper-button-next:after, .swiper-button-prev:after {
  display: none;
}

.swiper-button-next svg, .swiper-button-prev svg {
  width: 24px;
  height: 24px;
}

@media (max-width: 1024px) {
  .swiper-button-prev, .swiper-button-next {
    display: none !important;
  }
}
